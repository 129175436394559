var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "search-attr" }, [
        _c(
          "div",
          [
            _c("a-input", {
              staticStyle: { width: "150px", margin: "5px" },
              attrs: { size: "small", placeholder: "朝代/分类" },
              model: {
                value: _vm.searchParams.dynastyName,
                callback: function($$v) {
                  _vm.$set(_vm.searchParams, "dynastyName", $$v)
                },
                expression: "searchParams.dynastyName"
              }
            }),
            _c(
              "a-select",
              {
                staticClass: "w-200",
                attrs: {
                  size: "small",
                  allowClear: "",
                  placeholder: "选择类型"
                },
                model: {
                  value: _vm.searchParams.type,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParams, "type", $$v)
                  },
                  expression: "searchParams.type"
                }
              },
              [
                _c("a-select-option", { attrs: { value: 0 } }, [
                  _vm._v("朝代")
                ]),
                _c("a-select-option", { attrs: { value: 1 } }, [_vm._v("分类")])
              ],
              1
            ),
            _c(
              "a-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { size: "small" },
                on: { click: _vm.search }
              },
              [_vm._v("搜索")]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "a-button",
              {
                attrs: { size: "small" },
                on: {
                  click: function($event) {
                    return _vm.openModal({})
                  }
                }
              },
              [_vm._v("新增朝代/分类")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "table-content",
          staticStyle: { "padding-top": "20px" }
        },
        [
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.data,
              rowKey: function(record, n) {
                return record.id || n
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "setup",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a",
                        {
                          staticStyle: { "margin-right": "5px" },
                          on: {
                            click: function($event) {
                              return _vm.openModal(row)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "Are you sure delete this item?",
                            "ok-text": "Yes",
                            "cancel-text": "No"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.remove(row.id)
                            }
                          }
                        },
                        [
                          _c("a", { staticStyle: { "margin-left": "5px" } }, [
                            _vm._v("删除")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.innerTitle,
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "ruleForm",
                attrs: { model: _vm.forms, rules: _vm.rules }
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "新增类型选择", prop: "typeSelected" } },
                [
                  _c(
                    "a-select",
                    {
                      staticClass: "w-200",
                      attrs: { placeholder: "选择新增类型选择" },
                      model: {
                        value: _vm.addOrEditorType,
                        callback: function($$v) {
                          _vm.addOrEditorType = $$v
                        },
                        expression: "addOrEditorType"
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: "dynasty" } }, [
                        _vm._v("朝代")
                      ]),
                      _c("a-select-option", { attrs: { value: "classify" } }, [
                        _vm._v("分类")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.addOrEditorType
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label:
                          _vm.addOrEditorType === "dynasty" ? "朝代" : "分类",
                        prop: "dynastyName"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入朝代" },
                        model: {
                          value: _vm.forms.dynastyName,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "dynastyName", $$v)
                          },
                          expression: "forms.dynastyName"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.addOrEditorType
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "年份", prop: "dynastySort" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入年份" },
                        model: {
                          value: _vm.forms.dynastySort,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "dynastySort", $$v)
                          },
                          expression: "forms.dynastySort"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }