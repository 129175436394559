<template>
  <div class="content">
    <div class="search-attr">
      <div>
        <a-input v-model="searchParams.dynastyName" size='small' style="width:150px;margin:5px;" placeholder='朝代/分类'></a-input>
        <a-select
            v-model="searchParams.type"
            class="w-200"
            size="small"
            allowClear
            placeholder='选择类型'
        >
          <a-select-option :value='0'>朝代</a-select-option>
          <a-select-option :value='1'>分类</a-select-option>
        </a-select>
        <a-button  @click="search" size='small' style="margin-left:10px;">搜索</a-button>
      </div>
      <div>
        <a-button @click="openModal({})" size='small'>新增朝代/分类</a-button>
      </div>
    </div>
    <div class="table-content" style="padding-top:20px;">
      <a-table @change="changePage" :pagination='pagination' :loading='loading' :columns="columns" :data-source="data" :rowKey="(record,n)=>record.id||n">
        <div slot="setup" slot-scope="row">
          <a @click="openModal(row)" style='margin-right:5px'>编辑</a>
          <a-popconfirm
              title="Are you sure delete this item?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="remove(row.id)"
          >
            <a style='margin-left:5px'>删除</a>
          </a-popconfirm>
        </div>
      </a-table>
    </div>
    <a-modal
        v-model="visible"
        :title="innerTitle"
        ok-text="确认"
        cancel-text="取消"
        @cancel='resetForms'
        @ok="hideModal"
    >
      <a-form-model
          ref="ruleForm"
          :model="forms"
          :rules="rules"
          v-bind="layout"
      >
        <a-form-model-item label="新增类型选择" prop="typeSelected" >
          <a-select
              v-model="addOrEditorType"
              class="w-200"
              placeholder='选择新增类型选择'
          >
            <a-select-option value='dynasty'>朝代</a-select-option>
            <a-select-option value='classify'>分类</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="addOrEditorType" :label="addOrEditorType === 'dynasty' ? '朝代' : '分类'" prop="dynastyName" >
          <a-input placeholder='请输入朝代' v-model='forms.dynastyName'></a-input>
        </a-form-model-item>

        <a-form-model-item v-if="addOrEditorType" label="年份" prop="dynastySort" >
          <a-input placeholder='请输入年份' v-model='forms.dynastySort'></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  data(){
    return {
      columns:[
        {title:'ID',dataIndex:'id'},
        {title:'朝代/分类',dataIndex:'dynastyName'},
        {title:'类型分类',
          customRender:(text, row, index) => {
            if(row.type === 1){
              return <span style="color:green">分类</span>
            }
            if(row.type === 0){
              return <span>朝代</span>
            }
          }
        },
        {title:'年份',dataIndex:'dynastySort'},
        {title:'操作',align:'center',scopedSlots: { customRender: 'setup' }},
      ],
      addOrEditorType: undefined,
      data:[],
      searchParams:{
        pageNum: 1,
        pageSize: 10,
        type: undefined,
        dynastyName: undefined
      },
      rules:{
        dynastyName:[{ required: true, message: 'please enter', trigger: 'blur' }],
      },
      pagination:{
        total:0,
        current:1,
        showTotal:(total)=>`共${total}条`,
      },
      forms:{
        dynastyName: undefined,
        dynastySort: undefined,
        type: undefined,
      },
      visible:false,
      loading:false,
      innerTitle:'',
      layout:{
        labelCol: { span: 5 },//标签占5份  共24份
        wrapperCol: { span: 19 },
      },
    }
  },
  computed:{

  },
  watch:{},
  created(){
    this.getData(this.searchParams)
  },
  methods:{
    openModal(row){
      const {id} = row
      if (id) {
        this.addOrEditorType = row.type === 0 ? 'dynasty' : 'classify'
      }
      this.innerTitle = id ? '编辑朝代/分类' : '新增朝代/分类'
      this.forms = JSON.parse(JSON.stringify(row))
      this.visible = true
    },
    async getData(params){
      this.loading = true
      const res = await this.axios('/dq_admin/dynasty/newlist',{params:params})
      this.loading = false
      const {records,total} = res.data
      this.data = records
      this.pagination.total = total
    },
    search(){
      this.searchParams.pageNum = 1
      this.pagination.current = 1
      this.getData(this.searchParams)
    },
    changePage(page){
      const {current,pageSize} = page
      this.pagination.current = current
      this.searchParams.pageNum = current
      this.searchParams.pageSize = pageSize
      this.getData(this.searchParams)
    },
    resetForms(){
      this.forms = {}
      this.addOrEditorType = undefined
      this.$refs.ruleForm.resetFields();
    },
    hideModal(){
      if (!this.addOrEditorType) return this.$message.warn('请选择新增或编辑type')
      if (!this.forms.dynastyName) return this.$message.warn(this.addOrEditorType === 'dynasty' ? '请输入朝代名称' : '请输入分类名称')
      // if (this.addOrEditorType === 'dynasty' && !this.forms.dynastySort) return this.$message.warn('请输入年份')
      this.forms.type = this.addOrEditorType === 'dynasty' ? 0 : 1
      this.submit(this.forms)
    },
    submit(forms){
      const {id} = forms
      const url = id ? '/dq_admin/dynasty/edit' : '/dq_admin/dynasty/add'
      this.axios.post(url,forms).then(res=>{
        if(res.status == 'SUCCESS'){
          this.$message.success(res.message)
          this.visible = false
          this.getData(this.searchParams)
          this.addOrEditorType = undefined
        }
      })
    },
    async remove(params){
      const res = await this.axios('/dq_admin/dynasty/delDynastyById',{params:{id:params}})
      if(res.status == 'SUCCESS'){
        this.$message.success(res.message)
        this.getData(this.searchParams)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;}
.content{display: flex;flex-direction: column;
    .table-content{flex:1;overflow-y: auto;}
}
</style>